module.exports={
"label.account.name":`Primeiro nome`,
"label.account.copy":`Por favor copiar o código de verificação aleatória no email, e clicar no link para alterar a senha no passo seguinte.`,
"label.account.phone.length":`O número de telemóvel requer pelo menos 7 caracteres`,
"label.account.Start.trial":`FICA DIVERTIDO`,
"label.account.employees1000":`Mais de 1000 empregados`,
"label.account.other.Ways":`Outros métodos de login`,
"label.account.emailsync.button.cancel":`Cancelar`,
"label.account.rule":`Consiste em 816 dígitos e letras.`,
"label.account.password.update.success":`Senha alterada com sucesso.`,
"label.account.describe":`O CloudCC centrase na personalização digital, inteligência analítica e conectividade global, e é personalizado a pedido juntamente com o desenvolvimento de negócios empresariais.`,
"label.account.enter.Emailbox":`Por favor, introduza o seu endereço electrónico.`,
"label.account.password":`Senha`,
"label.account.problem":`O login falhou.`,
"label.account.Complete.reset":`Email. Por favor, introduza o código de verificação para completar a redefinição da palavrapasse.`,
"label.account.crm.used":`CRM utilizado por empresas cotadas`,
"label.account.report.loading":``,
"label.account.notcustomer":`Sem conta?`,
"label.account.employees501":`5011000 empregados`,
"label.account.title.operationManager":`Gestor de operações`,
"label.account.Preset":`Ferramentas prédefinidas de gestão de projectos`,
"label.account.company":`Empresa`,
"label.account.come":`Bemvindo à Plataforma Cloudcc para o Sucesso do Cliente. A nossa nova plataforma de raios proporcionalhe a solução mais rápida e completa, permitindolhe fazer tudo com o seu cliente no centro.  `,
"label.account.input.password":`Por favor, introduza a sua palavrapasse.`,
"label.account.emailobject.emaildetail.button.back":`Voltar`,
"label.account.title.itManager":`Gestor de TI`,
"label.account.Modified.successfully":`Mudança de senha com sucesso. Por favor, clique novamente em login para voltar à página de login.`,
"label.account.only11":`Por favor, introduza pelo menos 7 dígitos.`,
"label.account.signin":`Login`,
"label.account.register":`Registese para obter`,
"label.account.adopt":`A verificação passou!`,
"label.account.title.hrManager":`Gestor de Marketing/HR`,
"label.account.input.usernames":`Por favor introduza o seu nome de utilizador.`,
"label.account.only.Numbers":`O número de telefone só pode introduzir números.`,
"label.account.title.salesManager":`Gestor de vendas`,
"label.account.email":`Email`,
"label.account.click":`Clique`,
"label.account.and":`e`,
"label.account.mobile":`Telefone`,
"label.account.Privacy.policy":`Política de Privacidade`,
"label.account.password.isnull":`A palavrapasse não pode estar vazia.`,
"label.account.employees101":`101500 empregados`,
"label.account.remember.name":`Lembrar nome de utilizador`,
"label.account.Select.country":`Por favor, seleccione o seu país/região.`,
"label.account.distinguish":`A palavrapasse é sensível a maiúsculas e minúsculas.`,
"label.account.fillMobile":`Por favor introduza o seu número de telefone.`,
"label.account.Preconfigured":`Relatórios e painéis de bordo prédefinidos`,
"label.account.Select.job":`Por favor, seleccione a sua posição.`,
"label.account.employees21":`21100 empregados`,
"label.account.username":`Nome de utilizador`,
"label.account.Submit":`Submeter`,
"label.account.confirm":`Confirmação de conta`,
"label.account.contact":`Contactenos`,
"label.account.country":`País`,
"label.account.username.isnull":`O nome de utilizador não pode estar vazio.`,
"label.account.forget.password":`Esqueceuse da palavrapasse?`,
"label.account.Loading":`Carregamento`,
"label.account.goon":`Continuar`,
"label.account.Register.trial":`Inscrevase para um teste gratuito`,
"label.account.title.other":`Outros`,
"label.account.surname":`Sobrenome`,
"label.account.Fail":`A verificação falhou, por favor tente novamente.`,
"label.account.Terms.service":`Termos de Serviço`,
"label.account.Select.staff":`Por favor, seleccione o número de empregados.`,
"label.account.username.enter":`Por favor, introduza o seu apelido.`,
"label.account.trial":`Teste gratuito`,
"label.account.employees1":`120 empregados`,
"label.account.Quick.registration":`ou acesso rápido`,
"label.account.tabpage.ok":`Confirme`,
"label.account.normal.reregister":`Login novamente`,
"label.account.batchadd.newbatchpage.save.em":`O formato do email está incorrecto.`,
"label.account..user.title":`Título do trabalho`,
"label.account.No.credit.card.required":`Não é necessário cartão de crédito.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Précarregar dados de amostra`,
"label.account.title.hobbies":`Passatempos`,
"label.account.platform":`Nova plataforma de CRM inteligente, móvel e digital`,
"label.account.sent":`Email enviado`,
"label.account.Confirm.password":`Por favor, confirme a sua palavrapasse.`,
"label.account.fill":`Preencher tudo, por favor.`,
"label.account.user.resetpw":`Redefinir Palavrapasse`,
"label.account.Spell":`Arraste o selector para completar o puzzle.`,
"label.account.identifying.code":`Por favor, introduza o código de verificação.`,
"label.account.staff":`Empregados`,
"label.account.welcome":`Bemvindo ao CloudCC`,
"label.account.online.guidance":`Consultoria e instrução online`,
"label.account.login.CloudCC":`Login no CloudCC`,
"label.account.Company.name":`Por favor, introduza o nome da sua empresa.`,
"label.account.title.generalManager":`Director Geral`,
"label.account.enter.user":`Para redefinir a sua palavrapasse, introduza por favor o seu nome de utilizador CloudCC.`,
"label.account.sent.code":`O código de verificação foi enviado para`,
"label.account.pw.error.text":`As duas palavraspasse que introduziu não coincidiram.`,
"label.account.title.customerManager":`Gestor do Serviço de Apoio ao Cliente`,
"label.account.label.captcha":`Código de Verificação`,
"label.account.Trial.days":`Inscrevase para um teste gratuito de 30 dias`,
"label.account.please.enter.name":`Por favor introduza o seu primeiro nome.`,
"label.account.inspect":`Se não receber o email de confirmação do código de verificação, por favor verifique o spam.`,
"label.account.User.rule":`O nome de utilizador tem a forma de um endereço de correio electrónico.`,
"label.account.go.Login":`Já tem uma conta?`,
"label.account.Reading.consent":`Concordo com a`,
"label.account.Change.Password2":`Alterar Palavrapasse`,
"label.account.Loginby":`Loginby`,
"label.account.apply.partner":`Candidatese a tornarse um Parceiro CloudCC`,
"label.account.MobileLogin":`Móvel`,
"label.account.Registration":``,
"label.account.Italy":`Itália`,
"label.account.view.details":`Ver detalhe`,
"label.account.accept":`Por favor leia e aceite os Termos de Serviço e a Política de Privacidade`,
"label.account.China.Mainland":`China Continental`,
"label.account.NewPassword":`Por favor introduza a nova palavrapasse`,
"label.account.Mobile.not.empty":`O número de telemóvel não pode estar vazio`,
"label.account.Please.select":`Por favor escolha`,
"label.account.market.introduce":`Plataforma unificada de canais de marketing ajudao a ganhar mais leads`,
"label.account.IOS":`Scan para descarregar o aplicativo iOS`,
"label.account.failsend":`Falha no envio`,
"label.account.UpperLowerlAphanumeric.character":`São solicitadas letras maiúsculas e minúsculas, números e símbolos`,
"label.account.New.Password1":`Nova Palavrapasse`,
"label.account.succSend":`Foilhe enviado um email. Por favor, copie o código de verificação no email e reinicie a palavrapasse.`,
"label.account.Account.mobile":`Número de conta ou telemóvel`,
"label.account.Login.consent":`Ao efectuar o login, concorda com`,
"label.account.fillAreaCode":`Por favor introduza o código de área`,
"label.account.active.login":``,
"label.account.jp":`Japão`,
"label.account.partner.success1":`Registouse com sucesso como parceiro do CloudCC.`,
"label.account.Reset":`Reinicialização`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Por favor introduza a palavrapasse antiga`,
"label.account.Germany":`Alemanha`,
"label.account.title.content":`Inscrevase no CloudCC | 30 dias de teste grátis  Experimente agora!`,
"label.account.Taiwan":`Taiwan, China`,
"label.account.China.Station":`China`,
"label.account.sale.introduce":`A automatização das vendas ajudao a ganhar mais ofertas`,
"label.account.Back":`Passo Anterior`,
"label.account.market":`Nuvem de Marketing`,
"label.account.section.company":`Detalhes da empresa`,
"label.account.check.email":`o nosso gestor de conta irá contactálo dentro de algum tempo, por favor verifique o seu email a tempo`,
"label.account.characters":`5 caracteres`,
"label.account.International":`Internacional`,
"label.account.britain":`Reino Unido`,
"label.account.reg.fail.msg":`A inscrição falhou. Por favor, tente novamente mais tarde.`,
"label.account.success.reg":`registou com sucesso`,
"label.account.company.url":`Sítio Web`,
"label.account.CannotBeEmpty":`Não pode estar vazio`,
"label.account.phone.note":``,
"label.account.second":`{segundos depois`,
"label.account.Change.Password":`Altere a sua palavrapasse`,
"label.account.Netherlands":`Países Baixos`,
"label.account.pleaseAgree":`Por favor aceite e concorde com os Termos de Serviço e Política de Privacidade`,
"label.account.copyright":`CloudCC Inc. Todos os direitos reservados`,
"label.account.letter":`1 letra`,
"label.account.remember":`Agora lembrome`,
"label.account.accountNum":`Conta`,
"label.account.code.not.empty":`A captcha não pode estar vazia`,
"label.account.Spain":`Espanha`,
"label.account.Alphanumeric":`É necessária uma mistura de letras e números`,
"label.account.obtain":`Adquirir`,
"label.account.company.txt":`Por favor adicione o seu nome, se não tiver uma entidade comercial registada`,
"label.account.con.browser":``,
"label.account.Android":`Scan para descarregar a aplicação Android`,
"label.account.title":`CloudCC Free Trial  CloudCC.com`,
"label.account.partner.success2":`A nossa vontade é chegar até si dentro de algum tempo.`,
"label.account.Macao":`Macau, China`,
"label.account.Brazil":`Brasil`,
"label.account.service.introduce":`Processo de serviço sem costuras melhora a eficácia da resposta`,
"label.account.codeFailure":`Código de verificação inválido`,
"label.account.AccountLogin":`Conta`,
"label.account.pswComplexReqRules":`O comprimento mínimo da senha é {0}, e {1}.`,
"label.account.UpperLowerlAphanumeric":`São pedidas letras maiúsculas e minúsculas, e número`,
"label.account.active.username":``,
"label.account.rememberPassword":`Recordar palavrapasse`,
"label.account.HongKong":`Hon Kong, China`,
"label.account.oldPass":`Palavrapasse antiga`,
"label.account.Data.storage":`Os seus dados serão armazenados no centro de dados {0}.`,
"label.account.number":`1 número`,
"label.account.Poland":`Polónia`,
"label.account.service":`Nuvem de serviços`,
"label.account.Incorrect.format":`Formato ou número incorrecto, por favor reentrar`,
"label.account.remember.status":`Lembrate de mim`,
"label.account.sale":`Nuvem de vendas`,
"label.account.No.account.binding":`Nenhuma conta está vinculada. Por favor contacte o administrador`,
"label.account.Russia":`Rússia`,
"label.account.France":`França`,
"label.account.partner.content":`A CloudCC é uma empresa global que está agora a recrutar parceiros para explorar soluções em todas as indústrias. Venha e juntese ao nosso programa de afiliados!`,
"label.account.reg.phone.already":`Esta organização já existe para este telefone. Inicie a sessão ou utilize um telefone diferente para se inscrever.`,
"label.account.Confirm":`Confirmar nova senha`,
"label.account.VerificationLogin":`Verificar o Código`,
"label.account.USA":`Estados Unidos da América`,
"label.account.mobile.num":`Por favor introduza o número do telemóvel`,
"label.account.MinLength":`O comprimento mínimo da senha é`,
"label.account.change.your.password":`Por favor, altere agora a palavrapasse tendo em vista a segurança`,
"label.account.new.password":`Introduzir uma nova palavrapasse para {0}. Certifiquese de que inclui pelo menos:`,
"label.account.website.enter":`Por favor, introduza o endereço oficial do website`,
"label.account.pswComplexIsZero":``
}